<template>
  <div>
    <form class="form">
      <h2>{{$root.langText.info}}</h2>
      
      <div class="form__input">
        <input 
          type="text"
          v-model="fields.name"
        >
        <span>{{$root.langText.input.name}}</span>
      </div>


      <div class="form__input">
        <input 
          type="text"
          v-model="fields.nameFull"
        >
        <span>{{$root.langText.input.fullName}}</span>
      </div>


      <div class="form__input form__input--status">
        <b :style="{color: fields.type_direction_color}">{{fields.status}}</b>
        <span>{{$root.langText.input.status}}</span>
      </div>



      <Autocomplete 
        :data="{name: $root.langText.input.source, mod: 'clientsSource', key: 'clientsSource', input: fields.clientsSource, inputId: fields.clientsSource_id}" 
        @result="autocompleteResult" 
        v-if="ifStatusText(fields.clientsSource)"
      />
      


      <div class="form__input" v-if="ifStatusText(fields.additionalSign)">
        <input 
          type="text"
          v-model="fields.additionalSign"
        >
        <span>{{$root.langText.input.additionalSign}}</span>
      </div>


      <div class="form__checkbox" v-if="ifStatusCheckbox(fields.specialControl)">
        <label>
          <input 
            type="checkbox"
            :disabled="!$root.edit.can_edit_client"
            v-model="fields.specialControl"
          >
          <span>{{$root.langText.input.specialControl}}</span>
        </label>
      </div>


      <div class="form__checkbox" v-if="ifStatusCheckbox(fields.blacklist)">
        <label>
          <input 
            type="checkbox"
            :disabled="!$root.edit.can_edit_client"
            v-model="fields.blacklist"
          >
          <span>{{$root.langText.input.blacklist}}</span>
        </label>
      </div>

      <Autocomplete 
        :data="{name: $root.langText.input.city, mod: 'city', key: 'city', input: fields.city, inputId: fields.city_id}" 
        @result="autocompleteResult" 
        v-if="ifStatusText(fields.city)"
      />
      

      
      <!-- <div class="form__input" v-if="ifStatusText(fields.address)">
        <input 
          type="text"
          v-model="fields.address"
        >
        <span>Адреса</span>
      </div>


      <div class="form__input" v-if="ifStatusText(fields.site)">
        <input 
          type="text"
          v-model="fields.site"
        >
        <span>Сайт</span>
      </div> -->


      <Autocomplete 
        v-if="ifStatusText(fields.manager)"
        :data="{name: $root.langText.input.manager, mod: 'team', key: 'manager', input: fields.manager, inputId: fields.manager_id}" 
        @result="autocompleteResult" 
      />


      
      <Autocomplete 
        :data="{name: $root.langText.input.documentManager, mod: 'team', key: 'documentManager', input: fields.documentManager, inputId: fields.documentManager_id}" 
        @result="autocompleteResult" 
        v-if="
          $root.app == 'SoftProCrmAppRioTransTest' ||
          $root.app == 'SoftProCrmAppRioTrans'
        "
      />

      <Autocomplete 
        :data="{name: $root.langText.input.managerSales, mod: 'team', key: 'sales_manager', input: fields.sales_manager, inputId: fields.sales_manager_id}" 
        @result="autocompleteResult" 
        v-if="
          $root.app !== 'SoftProCrmAppRioTransTest' &&
          $root.app !== 'SoftProCrmAppRioTrans'
        "
      />


      <h2>{{$root.langText.input.titleTypeCompany}}</h2>
      <div class="form__row form__row-wrap">
        <div class="form__checkbox" v-if="ifStatusCheckbox(fields.type.forwarder)">
          <label>
            <input 
              type="checkbox"
              :disabled="!$root.edit.can_edit_client"
              v-model="fields.type.forwarder"  
            >
            <span>{{$root.langText.input.forwarder}}</span>
          </label>
        </div>

        <div class="form__checkbox" style="width:50%" v-if="ifStatusCheckbox(fields.type.producer)">
          <label>
            <input 
              type="checkbox"
              :disabled="!$root.edit.can_edit_client"
              v-model="fields.type.producer"  
            >
            <span>{{$root.langText.input.producer}}</span>
          </label>
        </div>

        <div class="form__checkbox" v-if="ifStatusCheckbox(fields.type.motorShow)">
          <label>
            <input 
              type="checkbox"
              :disabled="!$root.edit.can_edit_client"
              v-model="fields.type.motorShow"  
            >
            <span>{{$root.langText.input.motorShow}}</span>
          </label>
        </div>

        

        <div class="form__checkbox" v-if="ifStatusCheckbox(fields.type.broker)">
          <label>
            <input 
              type="checkbox"
              :disabled="!$root.edit.can_edit_client"
              v-model="fields.type.broker"  
            >
            <span>{{$root.langText.input.broker}}</span>
          </label>
        </div>
      </div>


      <h2>{{$root.langText.input.titleDirection}}</h2>
      <div class="form__row form__row-wrap">
        <div class="form__checkbox" v-if="ifStatusCheckbox(fields.direction.imports)">
          <label>
            <input 
              type="checkbox"
              :disabled="!$root.edit.can_edit_client"
              v-model="fields.direction.imports"
            >
            <span>{{$root.langText.input.imports}}</span>
          </label>
        </div>

        <div class="form__checkbox" v-if="ifStatusCheckbox(fields.direction.export)">
          <label>
            <input 
              type="checkbox"
              :disabled="!$root.edit.can_edit_client"
              v-model="fields.direction.export"
            >
            <span>{{$root.langText.input.export}}</span>
          </label>
        </div>

        <div class="form__checkbox" v-if="ifStatusCheckbox(fields.direction.transit)">
          <label>
            <input 
              type="checkbox"
              :disabled="!$root.edit.can_edit_client"
              v-model="fields.direction.transit"
            >
            <span>{{$root.langText.input.transit}}</span>
          </label>
        </div>

        <div class="form__checkbox" v-if="ifStatusCheckbox(fields.direction.internal)">
          <label>
            <input 
              type="checkbox"
              :disabled="!$root.edit.can_edit_client"
              v-model="fields.direction.internal"
            >
            <span>{{$root.langText.input.internal}}</span>
          </label>
        </div>

        <div class="form__checkbox" v-if="ifStatusCheckbox(fields.direction.doNotCarry)">
          <label>
            <input 
              type="checkbox"
              :disabled="!$root.edit.can_edit_client"
              v-model="fields.direction.doNotCarry"
            >
            <span>{{$root.langText.input.doNotCarry}}</span>
          </label>
        </div>
      </div>




      <h2>{{$root.langText.input.titleService}}</h2>
      <div class="form__row form__row-wrap" v-if="ifStatusCheckbox(fields.service.freight)">
        <div class="form__checkbox">
          <label>
            <input 
              type="checkbox"
              :disabled="!$root.edit.can_edit_client"
              v-model="fields.service.freight"
            >
            <span>{{$root.langText.input.freight}}</span>
          </label>
        </div>

        <div class="form__checkbox" v-if="ifStatusCheckbox(fields.service.auto)">
          <label>
            <input 
              type="checkbox"
              :disabled="!$root.edit.can_edit_client"
              v-model="fields.service.auto"
            >
            <span>{{$root.langText.input.auto}}</span>
          </label>
        </div>

        <div class="form__checkbox" v-if="ifStatusCheckbox(fields.service.railway)">
          <label>
            <input 
              type="checkbox"
              :disabled="!$root.edit.can_edit_client"
              v-model="fields.service.railway"
            >
            <span>{{$root.langText.input.railway}}</span>
          </label>
        </div>

        <div class="form__checkbox" v-if="ifStatusCheckbox(fields.service.aviation)">
          <label>
            <input 
              type="checkbox"
              :disabled="!$root.edit.can_edit_client"
              v-model="fields.service.aviation"
            >
            <span>{{$root.langText.input.aviation}}</span>
          </label>
        </div>

        <div class="form__checkbox" v-if="ifStatusCheckbox(fields.service.lcl)">
          <label>
            <input 
              type="checkbox"
              :disabled="!$root.edit.can_edit_client"
              v-model="fields.service.lcl"
            >
            <span>{{$root.langText.input.lcl}}</span>
          </label>
        </div>

        <div class="form__checkbox" v-if="ifStatusCheckbox(fields.service.teo)">
          <label>
            <input 
              type="checkbox"
              :disabled="!$root.edit.can_edit_client"
              v-model="fields.service.teo"
            >
            <span>{{$root.langText.input.teo}}</span>
          </label>
        </div>

        <div class="form__checkbox" v-if="ifStatusCheckbox(fields.service.transshipment)">
          <label>
            <input 
              type="checkbox"
              :disabled="!$root.edit.can_edit_client"
              v-model="fields.service.transshipment"
            >
            <span>{{$root.langText.input.transshipment}}</span>
          </label>
        </div>

        <div class="form__checkbox" v-if="ifStatusCheckbox(fields.service.customsCleaning)">
          <label>
            <input 
              type="checkbox"
              :disabled="!$root.edit.can_edit_client"
              v-model="fields.service.customsCleaning"
            >
            <span>{{$root.langText.input.customsCleaning}}</span>
          </label>
        </div>
      </div>







      <div class="form__input" v-if="ifStatusText(fields.loads)">
        <input 
          type="text"
          v-model="fields.loads"
        >
        <span>{{$root.langText.input.loads}}</span>
      </div>


      <a class="btn-save btn btn-primary" v-if="$root.edit.can_edit_client" href="#" @click.prevent="sendForm">{{$root.langText.save}}</a>
      <Message v-if="message.popup" :message="message" @close="message.popup = false"/>
    </form> 
  
  </div>
</template>




<script>
import {required} from 'vuelidate/lib/validators'
import Autocomplete from "@/components/app/Autocomplete"
import Message from "@/components/app/Message"


export default {
  props: ['id'],
  data: () => ({
    fields: {
      name: null,
      nameFull: null,
      status: 'Потенційний',
      type_direction_color: '#0286E5',
      clientsSource: '',
      clientsSource_id: '',
      additionalSign: null,
      specialControl: false,
      blacklist: false,
      cityImg: '/img/country/ua.png',
      city: null,
      city_id: null,
      address: null,
      site: null,
      manager: null,
      manager_id: null,
      documentManager: null,
      documentManager_id: null,
      sales_manager: null,
      sales_manager_id: null,
      type: {
        forwarder: false,
        producer: false,
        motorShow: false,
        broker: false,
      },
      direction: {
        imports: false,
        export: false,
        transit: false,
        internal: false,
        doNotCarry: false,
      },
      service: {
        freight: false,
        auto: false,
        railway: false,
        aviation: false,
        lcl: false,
        teo: false,
        transshipment: false,
        customsCleaning: false
      },
      loads: null
    },


    clientsId: null,
    //message
    message: {
      status: '',
      popup: false,
      message: ''
    }
  }), 



  
  created() {
    if(this.$route.params.id){
      function boolean(val){
        return (val == 0) ? (false) : true
      }
      if(this.$root.data.client){
        this.clientsId = this.$root.data.client
        this.fields.city = this.clientsId.city_name
      } else{
        this.clientsId = this.id
        this.fields.city = this.clientsId.city_info ? this.clientsId.city_info.name : ''
      }
      

      this.fields.name = this.clientsId.name
      this.fields.nameFull = this.clientsId.nameFull
      this.fields.status = this.id.status_name
      this.fields.type_direction_color = this.id.status_color_bg
      this.fields.clientsSource = this.clientsId.source_name
      this.fields.clientsSource_id = this.clientsId.sourceGroup
      this.fields.additionalSign = this.clientsId.dopPriznak
      this.fields.specialControl = boolean(this.clientsId.specialControl)
      this.fields.blacklist = boolean(this.clientsId.blackList)
      this.fields.cityImg = this.clientsId.image
      // this.fields.address = this.clientsId
      // this.fields.site = this.clientsId
      this.fields.manager = this.clientsId.customer_manager_name
      this.fields.manager_id = this.clientsId.customer_manager
      this.fields.documentManager = this.clientsId.doc_support_manager_name
      this.fields.documentManager_id = this.clientsId.doc_support_manager_id
      if(this.$root.app !== 'SoftProCrmAppRioTransTest' && this.$root.app !== 'SoftProCrmAppRioTrans'){
        this.fields.sales_manager = this.clientsId.salles_manager_name
        this.fields.sales_manager_id = this.clientsId.salles_manager
      }
      

      this.fields.type.forwarder = boolean(this.clientsId.expeditor)
      this.fields.type.producer = boolean(this.clientsId.proizvoditel)
      this.fields.type.motorShow = boolean(this.clientsId.avtosalon)
      this.fields.type.broker = boolean(this.clientsId.broker)

      this.fields.direction.imports = boolean(this.clientsId.import)
      this.fields.direction.export = boolean(this.clientsId.export)
      this.fields.direction.transit = boolean(this.clientsId.tranzit)
      this.fields.direction.internal = boolean(this.clientsId.vnutrennie)
      this.fields.direction.doNotCarry = boolean(this.clientsId.notWork)

      this.fields.service.freight = boolean(this.clientsId.fraht)
      this.fields.service.auto = boolean(this.clientsId.avto)
      this.fields.service.railway = boolean(this.clientsId.gd)
      this.fields.service.aviation = boolean(this.clientsId.avia)
      this.fields.service.lcl = boolean(this.clientsId.lcl)
      this.fields.service.teo = boolean(this.clientsId.teo)
      this.fields.service.transshipment = boolean(this.clientsId.perevalka)
      this.fields.service.customsCleaning = boolean(this.clientsId.tamOchistka)

      this.fields.loads = this.clientsId.cargo
    }
  }, 
  mounted() {
  },
  methods: {
    //hidden inputs
    ifStatusText(input){
      if(input !== null || this.$root.edit.can_edit_client) return true
    },
    ifStatusCheckbox(input){
      if(input || this.$root.edit.can_edit_client) return true
    },

    //autocomplete
    autocompleteResult(data){
      this.fields[data.input] = data.name
      this.fields[data.input + '_id'] = data.id
      if(data.img){
        this.fields.cityImg = data.img
      }
    },

    //send data
    async sendForm(){
      function boolean(val){
        return (val == false) ? 0 : 1
      }

      var data = {
        name: this.fields.name || '',
        full_name: this.fields.nameFull || '',
        source_group: this.fields.clientsSource_id,
        dop_priznak: this.fields.additionalSign || '',
        specialControl: boolean(this.fields.specialControl),
        blackList: boolean(this.fields.blacklist),
        city: this.fields.city_id || '',
        // this.fields.address = this.clientsId
        // this.fields.site = this.clientsId
        customer_manager: this.fields.manager_id || '',
        doc_support_manager_id: this.fields.documentManager_id || '',
        salles_manager: this.fields.sales_manager_id || '',

        expeditor: boolean(this.fields.type.forwarder),
        proizvoditel: boolean(this.fields.type.producer),
        avtosalon: boolean(this.fields.type.motorShow),
        broker: boolean(this.fields.type.broker),

        import: boolean(this.fields.direction.imports),
        export: boolean(this.fields.direction.export),
        tranzit: boolean(this.fields.direction.transit),
        vnutrennie: boolean(this.fields.direction.internal),
        notWork: boolean(this.fields.direction.doNotCarry),

        fraht: boolean(this.fields.service.freight),
        avto: boolean(this.fields.service.auto),
        gd: boolean(this.fields.service.railway),
        avia: boolean(this.fields.service.aviation),
        lcl: boolean(this.fields.service.lcl),
        teo: boolean(this.fields.service.teo),
        perevalka: boolean(this.fields.service.transshipment),
        tamOchistka: boolean(this.fields.service.customsCleaning),

        cargo: this.fields.loads
      }
      if(this.$route.params.id){
        data.id = this.clientsId.id
        var update = await this.$store.dispatch('updateClients', data);
        this.$emit('updateData')
        this.$root.title[0].title = this.fields.name
        
        //messedge
        if(update.data == 'ok'){
          this.message.status = update.data
          this.message.popup = true
          this.message.message = this.$root.langText.changes.save;
        } else{
          this.message.status = 'error'
          this.message.popup = true
          this.message.message = this.$root.langText.changes.error
        }
      }else{
        var create = await this.$store.dispatch('createClients', data)
        if(create.data.status == 'error'){
          this.message.status = 'error'
          this.message.popup = true
          this.message.message = this.$root.langText.changes.fillIn
        } else{
          this.$router.push('/clients')
        }
        
      }
    }
  },

  beforeDestroy() {
    function boolean(val){
      return (val == false) ? 0 : 1
    }

    var data = {
      name: this.fields.name || '',
      nameFull: this.fields.nameFull || '',
      sourceGroup: this.fields.clientsSource_id,
      source_name: this.fields.clientsSource || '',
      dopPriznak: this.fields.additionalSign || '',
      specialControl: boolean(this.fields.specialControl),
      blackList: boolean(this.fields.blacklist),
      city: this.fields.city_id || '',
      city_name: this.fields.city || '',
      customer_manager: this.fields.manager_id || '',
      customer_manager_name: this.fields.manager || '',
      doc_support_manager_id: this.fields.documentManager_id || '',
      doc_support_manager_name: this.fields.documentManager || '',
      salles_manager_name: this.fields.sales_manager || '',
      salles_manager: this.fields.sales_manager_id || '',
      

      expeditor: boolean(this.fields.type.forwarder),
      proizvoditel: boolean(this.fields.type.producer),
      avtosalon: boolean(this.fields.type.motorShow),
      broker: boolean(this.fields.type.broker),

      import: boolean(this.fields.direction.imports),
      export: boolean(this.fields.direction.export),
      tranzit: boolean(this.fields.direction.transit),
      vnutrennie: boolean(this.fields.direction.internal),
      notWork: boolean(this.fields.direction.doNotCarry),

      fraht: boolean(this.fields.service.freight),
      avto: boolean(this.fields.service.auto),
      gd: boolean(this.fields.service.railway),
      avia: boolean(this.fields.service.aviation),
      lcl: boolean(this.fields.service.lcl),
      teo: boolean(this.fields.service.teo),
      perevalka: boolean(this.fields.service.transshipment),
      tamOchistka: boolean(this.fields.service.customsCleaning),

      cargo: this.fields.loads
    }
    if(this.id){
      data.id = this.id.id
    }
    this.$root.data.client = data
  },
  components:{
    Autocomplete, Message
  }
}
</script>


