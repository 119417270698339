<template>
  <form class="form">
    <div class="block-slide-toggle">
      <h2>{{$root.langText.contacts.contactInformation}}</h2>
      <ul class="block-list" v-if="contacts !== undefined">
        <li
          v-for="(list, index) in contacts"
          :key="index"
          @click.prevent="openList('update', index)"
          :data-id="list.id"
        >
          <h5>{{list.name}}</h5>
          <span style="margin-top: -5px; margin-bottom: 10px">{{list.position}}</span>
          <p v-if="list.contacts && list.contacts[0]">{{list.contacts[0].val}}</p>
          <p v-if="list.contacts && list.contacts[1]">{{list.contacts[1].val}}</p>
          <a class="icon icon-arrow-right"></a>
        </li>
      </ul>

      <ul class="block-list" v-if="contacts !== undefined">
        <li
          v-for="(list, index) in contactsOne[0]"
          :key="index"
          @click.prevent="openList('updateOne', index)"
          :data-id="list.id"
        >
          <p style="margin-bottom: 0;padding: 6px 0;">{{list.val}}</p>
          <a class="icon icon-arrow-right"></a>
        </li>
      </ul>

      <p v-else class="block-slide-none">{{$root.langText.contacts.contactsNo}}</p>
      <a class="btn btn-transparent" @click.prevent="openList('create')"  v-if="$root.edit.can_edit_client">{{$root.langText.btnAdd}}<div class="icon-plus"></div></a>
    </div>

    <div class="block-slide-toggle">
      <h2>{{$root.langText.directory.counterparties}}</h2>
      <ul class="block-list" v-if="counterpartiesList.length !== 0">
        <router-link 
          tag="li"
          :key="index"
          v-for="(counterpartie, index) in counterpartiesList"
          :id="counterpartie.id"
          :to = "{name: 'СounterpartiesCreateId', params: {id: counterpartie.id}}" 
          
        >
          <i :style="{background: counterpartie.status_color_bg}"></i>
          <strong :style="{color: counterpartie.status_color_bg}">{{counterpartie.funnel_stage_name}}</strong>
          <h4>{{counterpartie.name}}</h4>
          <span>{{$root.langText.input.legalForm}}</span>
          <b>{{counterpartie.status}}</b>
          <a class="icon icon-arrow-right"></a>

        </router-link >
      </ul>
      <p v-else class="block-slide-none">{{$root.langText.changes.counterpartiesNo}}</p>
      <div v-if="$root.add.can_add_contractor">
        <a class="btn btn-transparent" v-if="$root.edit.can_edit_client" @click.prevent="counterpartiesNew()">{{$root.langText.btnAdd}}<div class="icon-plus"></div></a>
      </div>
    </div>

    <ContactPopup v-if="contact_popup" :hidden="$root.edit.can_edit_client" :mod="'client'" :id="id.id" :modOne="true" :data="contact_popupData" @closeContact="closeContact"/>
    <ContactsPerson v-if="ContactsPerson.flag" :hidden="$root.edit.can_edit_client" :info="ContactsPerson" :id="id.id" :dataPerson="contacts" @closeContact="closeContact" @close="ContactsPerson.flag = false" @update="update()" /> 
  </form>
</template>

<style lang="scss">
  .decor-line{
    margin: 20px 0;
  }
  .block-slide-none{
    color: #8EB6E3;
    font-size: 16px;
    text-align: center;
    margin: 20px 0;
  }
</style>



<script>
import ContactsPerson from '@/components/contacts/ContactsPerson'
import ContactPopup from '@/components/contacts/ContactPopup'



export default {
  props: ['id'],
  data: () => ({
    contacts: [],
    contactsOne: [],
    contact_popupData: '',
    contact_popup: false,
    ContactsPerson: {flag: false, id: '', index: '', modPerson: 'client', mod: 'create'},
    dataPerson: '',

    counterpartiesList: '',
    counterparties: false
  }),

  watch:{
    id: function(){
      this.contacts = []
      this.contactsOne = []
      var app = this
      this.id.contacts_persons.forEach(function(item, index){
        if(item.name){
          app.contacts.push(item)
        } else{
          app.contactsOne.push(item.contacts)
        }
      })
    }
  },


  created() {
    if(this.$route.params.id && this.$route.params.id != 'create'){
      if(this.id){
        var app = this
        this.id.contacts_persons.forEach(function(item, index){
          if(item.name){
            app.contacts.push(item)
          } else{
            app.contactsOne.push(item.contacts)
          }
        })

        this.counterpartiesList = this.id.contractors
      }
    }
  },
  methods: {
    update(){
      this.$emit('updateData')
    },

    counterpartiesNew(){
      localStorage.setItem('clientId', this.id.id)
      this.$router.push('/counterparties/new')
    },

    closeContact(data){
      this.ContactsPerson.flag = false
      this.contact_popup = false
      this.$emit('updateData')
    },

    openList(mod, index){
      if(mod == 'update'){
        this.ContactsPerson.mod = 'update'
        this.ContactsPerson.index = index
        this.ContactsPerson.id = this.id.id
        this.ContactsPerson.flag = !this.ContactsPerson.flag
      } else if(mod == 'updateOne'){
        this.contact_popupData = this.contactsOne[0][index]
        this.contact_popup = !this.contact_popup
      }else{
        this.ContactsPerson.mod = 'create'
        this.ContactsPerson.id = this.id.id
        this.ContactsPerson.flag = !this.ContactsPerson.flag
      }
    }
  },
  components: {
    ContactsPerson, ContactPopup
  }
}
</script>