<template>
  <div class="container" >
    <div class="tabs"
      :class="$root.edit.can_edit_client ? '' : 'hide'"
      v-if="clientsId"
    >
      <ul class="tabs__caption" v-if="$route.params.id">
        <li @click="activetab=1" v-bind:class="[ activetab === 1 ? 'active' : '' ]">{{$root.langText.general}}</li>
        <li @click="activetab=2" v-bind:class="[ activetab === 2 ? 'active' : '' ]">{{$root.langText.contactTitle}}</li>
        <li @click="activetab=3" v-bind:class="[ activetab === 3 ? 'active' : '' ]">{{$root.langText.input.history}}</li>
      </ul>
      
      <!-- tab 1 -->
      <div class="tabs__content" v-if="activetab === 1 && clientsId !== null">
        <ClientsInfo :id="clientsId" @updateData="updateData"/>
      </div>
  
      

      <!-- tab 2 -->
      <div class="tabs__content" v-if="activetab === 2">
        <ClientsContacts :id="clientsId" @updateData="updateData"/>
      </div>

      <!-- tab 3 -->
      <div class="tabs__content" v-if="activetab === 3">
        <ClientsHistory :id="clientsId" @updateData="updateData"/>
      </div>
    </div>
  </div>
</template>




<script>
import ClientsInfo from '@/components/clients/ClientsInfo'
import ClientsContacts from '@/components/clients/ClientsContacts'
import ClientsHistory from '@/components/clients/ClientsHistory'
import axios from "axios"


export default {
  data: () => (
    { 
      activetab: 1, 
      clientsId: null,
      agreementsLength: '',
      //Title
      globalTitle: [{title: 'Новий клієнт', info: 'Клієнт'}],
    }
  ),



  async mounted(){

    if(this.$route.params.id){
      const id = this.$route.params.id
      const res = await this.$store.dispatch('getClientsId', id)
      this.clientsId = res.data.detail
      if(this.clientsId){

        //Title
        this.$root.title[0].title = this.clientsId.name
        this.$root.title[0].info = this.$root.langText.input.client 
      }


      
    } else{
      this.clientsId = []
      this.create = true
      //title
      this.$root.title[0].title = this.$root.langText.newSecondary + ' ' + this.$root.langText.input.client.toLowerCase();
      this.$root.title[0].info = ''


    }

  },
  methods: {
    async updateData(){
      const id = this.$route.params.id
      const res = await this.$store.dispatch('getClientsId', id)
      this.clientsId = res.data.detail
    }
  },


  destroyed() {
    this.$root.data.client = ''
  },
  components: {
    ClientsInfo, ClientsContacts, ClientsHistory
  },
  metaInfo(){
    return {
      title: this.$title(this.$root.langText.directory.clients)
    }
  },
}
</script>